import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import userService from "./userService"

// Get user from localStorage
// const user = JSON.parse(localStorage.getItem('user'))


const initialState = {
    notes: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    currentUser: null,
}


// Get One User @ /api/users/:userId
export const getUserById = createAsyncThunk("users/getUserById", 
    async (_userId, thunkAPI) => {
        try {
            const { notes } = thunkAPI.getState().users.notes
            return await userService.getUserById(_userId, notes) //, note
        } catch (error) {
            const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
        }
    }
)


///////

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserById.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUserById.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.currentUser = action.payload
                state.notes = action.payload

            })
            .addCase(getUserById.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }
}) 


export const { reset } = userSlice.actions
export default userSlice.reducer