import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { editProfile, getProfile, reset } from "../../features/reducers/auth/authSlice"
import  profilePhotoUpload  from "../../components/notes/profilePhotoUpload"
import AddImage from "../../components/notes/AddImage"


function ProfileEdit({ closeEditMode}){

  const currentUsers = useSelector(state => state.auth)
  const { currentUser } = currentUsers

  const currentNotes = useSelector(state => state.notes)
  const { currentNote, isError, message } = currentNotes

  const dispatch = useDispatch()

  const [image, setImage] = useState("")
  const [imageUpload, setImg] = useState(false)

////////

  const onSubmit = (e) => {
    e.preventDefault()
    if (imageUpload) return
    closeEditMode()

    let payload = {
      _id: currentUsers?.user?._id,
      image: image,
    }
    dispatch(editProfile(payload))
    setImage("")
    getProfile()
    reset()
  }
  
////////

  const handleFileChange = async event => {
    const [image] = event.target.files
      
    if (!image) return
    setImg(true)
      
    const uploadedUrl = await profilePhotoUpload(image)
    setImage( uploadedUrl )
    setImg(false)
  }


////////

  return (
    <>  
      <AddImage imageUpload={handleFileChange} image={imageUpload.image}/>
      <div className="mt-4">
        <button type="submit" onClick={onSubmit} disabled={imageUpload} className="text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-semibold rounded-lg text-sm px-5 py-2.5 text-center mr-2 sm:w-[100px]">
          UPDATE
        </button>
              
        <button onClick={closeEditMode} className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group  dark:hover:text-gray-900 focus:ring-4 focus:outline-none bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white dark:text-white focus:ring-green-200 dark:focus:ring-green-800 sm:w-[100px]" type="submit">
          <span className="relative px-4 py-2 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0 sm:w-[200px]">
            CANCEL
          </span>
        </button>
      </div>    
    </>
  )
}



export default ProfileEdit