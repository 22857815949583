import axios from "axios"
const API_NOTE_URL = "/api/notes/" // "http://localhost:3000/api/notes/" "https://wandernotes.onrender.com/api/notes/" || 


// Create New Note @ /api/notes/ (POST request)
const createNote = async (noteData) => {
    const config = {
        method: "POST",
        data:  noteData,
        withCredentials: true,
        url: `${API_NOTE_URL}new`,
    }

    const response = await axios.post(`${API_NOTE_URL}new`, noteData, config )
    return response.data
}


// Edit a Note @ /api/notes/:id (PUT request)
const updateNote = async ( noteData, _id ) => {

    const config = {
        method: "PUT",
        data: noteData, _id,
        withCredentials: true,
    }
    const response = await axios.put(`${API_NOTE_URL}${_id}`, noteData, _id, config)
    return response.data
}


// Get All Notes @ /api/notes/ (GET request)
const getNotesAll = async () => {
    const config = {
        method: "GET",
        withCredentials: true,
        url: API_NOTE_URL,
    }
    const response = await axios.get(API_NOTE_URL, config)
    return response.data
}


// Get One Note @ /api/notes/:id (GET request)
const getNoteByID = async (_id) => {
    const config = {
        method: "GET",
        withCredentials: true,
        // url: API_NOTE_URL + id,
    }
    const response = await axios.get(`${API_NOTE_URL}${_id}`, config)
    return response.data
}


// Delete a Note @ /api/notes/:id (DELETE request)
const deleteNote = async (_id) => {
    const config = {
        method: "DELETE",
        withCredentials: true,
        //url: API_NOTE_URL + _id,
    }
    const response = await axios.delete(`${API_NOTE_URL}${_id}`, config)
    return response.data
}



const noteService = {
    createNote,
    updateNote,
    getNoteByID,
    getNotesAll,
    deleteNote,
}
  
export default noteService