import { useSelector } from "react-redux"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"


function Index() {

  const { user } = useSelector((state) => state.auth)

  const navigate = useNavigate()

///////

  useEffect(() => {
    if(user) {
      navigate("/profile")
    }
  }, [user, navigate])


///////

  return (
    <>
      <div className="flex justify-between items-center h-full bg-center bg-cover" style={{ minHeight: "60vh", backgroundImage: "url('https://images.unsplash.com/photo-1508614999368-9260051292e5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80')" }}>
        <div className="bg-white w-full">
          <div className="items-center flex flex-wrap ">
            <div className="w-full text-center ">
              <div className="px-4 py-8">
                <h1 className="text-black text-5xl xl:font-extralight font-hairline xs:text-4xl">
                  Your wanderlust starts with <span className="font-extralight xl:font-light">WanderNotes</span>.
                </h1>
                {/* <p className="mt-4 text-lg text-black"></p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default Index