import { createRef, useState } from "react"
import Image from "../../components/social-icons/avatar.png"


const AddImage = (props) => {
  // const { user } = useSelector((state) => state.auth)

  const [isLoading, setIsLoading] = useState(true)

  const [image, _setImage] = useState()
  const inputFileRef = createRef()

///////

  const cleanup = () => {
    URL.revokeObjectURL(image && props.image)
    inputFileRef.current.value = null
  }
  const setImage = (newImage) => {
    if (image) {
      cleanup()
    }
    _setImage(newImage)
  }

  const handleOnChange = (event) => {
    const newImage = event.target.files[0]
    if (newImage) {
        setImage(URL.createObjectURL(newImage))
    }
    props.imageUpload(event)
    // console.log({newImage})
    }

    function onLoad() {
     setIsLoading(false)
    }
  

///////

  return (
    <>
      <div className="">
        <div className="justify-center flex">
          <img 
            src={Image}
            alt="avatar"
            className="h-48 w-48 rounded-full min-h-[192px] min-w-[192px]" 
            style={{ display: isLoading ? "block" : "none" }}
          />
          <img 
            src={image}
            alt="avatar"
            className="h-48 w-48 rounded-full min-h-[192px] min-w-[192px]" 
            style={{ display: isLoading ? "none" : "block" }}
            onLoad={onLoad} 
          />
        </div>
        <div className="">
          <input 
            ref={inputFileRef} 
            accept="image/*" 
            // hidden 
            id="avatar-image-upload" 
            type="file" 
            onChange={handleOnChange}
            className="border border-gray-300 rounded-md w-[210px] mt-4"
          />
        </div>
      </div>
    </>
  )
}


export default AddImage







///////////////////// i dunno, before i copied the working stuff above





// import { useEffect, useRef, createRef, useState } from "react"
// import { useDispatch, useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
// import { profileUpload } from "../../features/data/imageUpload"
// import { createNote } from "../../features/reducers/notes/noteSlice"
// import ImageShow from "../notes/ImageShow"


// const AddImage = () => { // props

//     const { user } = useSelector((state) => state.auth)
//     const { notes, isSuccess, isError, message } = useSelector((state) => state.notes)

//     const [media, setMedia] = useState([]) // const [image, _setImage] = useState()
//     const refDisplay = useRef() // const inputFileRef = createRef()

// ///////////

//     // const cleanup = () => {
//     //     URL.revokeObjectURL(image && props.image)
//     //     inputFileRef.current.value = null
//     // }
//     // const setImage = (newImage) => {
//     //     if (image) {
//     //         cleanup()
//     //     }
//     //     _setImage(newImage)
//     // }
//     // const handleOnChange = (event) => {
//     //     const newImage = event.target.files[0]
//     //     if (newImage) {
//     //         setImage(URL.createObjectURL(newImage))
//     //     }
//     //     props.imageUpload(event)
//     //     // console.log({newImage})
//     // }

// //////////////

//   const handleOnChange = (e) => { // handleChangeMedia
//     const files = [...e.target.files]
//   let newMedia = []

//   files.forEach((file) => {
//     if (!file) {
//       return ("File does not exist.")
//     }
//     if (file.size > 1024 * 1024 * 5) {
//       return ("Image size must be less than 5 mb.")
//     }
//     return newMedia.push(file);
//   })
  
//   setMedia([...media, ...newMedia])
//   }

// //   const handleSubmit = async e => {
// //     e.preventDefault()
// //     // if(!text.trim() && media.length === 0) return;
// //     // // setText('')
// //     // setMedia([])

// //     let newArr = []
// //     if(media.length > 0) newArr = await imageUpload(media);

// //     const msg = {
// //       sender: user._id,
// //       recipient: id,
// //       text,
// //       media: newArr,
// //       createdAt: new Date().toISOString()
// //     }
// //   }

//         // <div
//         //   className="show_media"
//         //   style={{ display: media.length > 0 ? "" : "none" }}
//         // >
//         // </div>


//   return (

//     <div>
//       {/* <img
//         src={image}
//         alt="uploaded pics"
//         style={{ width: "110px", borderRadius: "50%", height: "100px" }}
//       /> */}
//       <div>
//       {/* // key={index} */}
//           {media.map((item) => (
//             <div id="file_media">
//               <img
//                 src={item}
//                 className="img-thumbnail"
//                 alt="uploaded pics"
//               />
//             </div>
//           ))}
//       </div>
//       <input 
//         //ref={inputFileRef} 
//         accept="image/*" 
//         // hidden 
//         id="file" // id="avatar-image-upload" 
//         name="file"
//         type="file" 
//         onChange={handleOnChange}
//       />
//       {/* <label htmlFor="avatar-image-upload">
//       <button className="btn btn-block" type ="submit">
//             Add Photo
//       </button>
//       </label> */}
//     </div>
//   )
// }

// export default AddImage





////////////////


// function AddImage() {

//     // const [error, setError] = useState();

//     // const [image, setImage] = useState();

// return (

//       <div className="w-100" style={{ maxWidth: "400px" }}>
//         <>
//               <h2 className="text-center mb-4">Add Image</h2>
//               <Form onSubmit={handleSubmit}>
//                 <Form.Group>
//                   <Form.File
//                     className="position-relative mt-2"
//                     name="file"
//                     accept="image/*"
//                     onChange={(e) => setImage(e.target.files[0])}
//                     id="validationFormik107"
//                     feedbackTooltip
//                   />
//                 </Form.Group>
//               </Form>
//         </>
//       </div>
//   )
// }

// export default AddImage





//////////// BEFORE CHANGING, WORKS


// import { createRef, useState } from "react"

// const AddImage = (props) => {
//     const [image, _setImage] = useState()
//     const inputFileRef = createRef()

//     const cleanup = () => {
//         URL.revokeObjectURL(image && props.image)
//         inputFileRef.current.value = null
//     }
//     const setImage = (newImage) => {
//         if (image) {
//             cleanup()
//         }
//         _setImage(newImage)
//     }
//     const handleOnChange = (event) => {
//         const newImage = event.target.files[0]
//         if (newImage) {
//             setImage(URL.createObjectURL(newImage))
//         }
//         props.imageUpload(event)
//         // console.log({newImage})
//     }

//   return (

//     <div>
//       <img
//         src={image}
//         alt="uploaded pics"
//         style={{ width: "110px", borderRadius: "50%", height: "100px" }}
//       />
//       <input 
//         ref={inputFileRef} 
//         accept="image/*" 
//         // hidden 
//         id="avatar-image-upload" 
//         type="file" 
//         onChange={handleOnChange}
//       />
//       {/* <label htmlFor="avatar-image-upload">
//       <button className="btn btn-block" type ="submit">
//             Add Photo
//       </button>
//       </label> */}
//     </div>
//   )
// }

// export default AddImage





// ////////////////


// // function AddImage() {

// //     // const [error, setError] = useState();

// //     // const [image, setImage] = useState();

// // return (

// //       <div className="w-100" style={{ maxWidth: "400px" }}>
// //         <>
// //               <h2 className="text-center mb-4">Add Image</h2>
// //               <Form onSubmit={handleSubmit}>
// //                 <Form.Group>
// //                   <Form.File
// //                     className="position-relative mt-2"
// //                     name="file"
// //                     accept="image/*"
// //                     onChange={(e) => setImage(e.target.files[0])}
// //                     id="validationFormik107"
// //                     feedbackTooltip
// //                   />
// //                 </Form.Group>
// //               </Form>
// //         </>
// //       </div>
// //   )
// // }

// // export default AddImage
