import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import NoteForm from "../../components/notes/NoteForm"


function NoteCreate() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.auth)
  const { isSuccess, isError, message } = useSelector((state) => state.notes)


  useEffect(() => {
    // if(isError) {
    //   console.log(message)
    // }
    if(!user) {
      navigate("/login")
    }
}, [user, isError, isSuccess, message, navigate, dispatch])

  return (
    <>
      <article>
        <div className="xl:divide-y xl:divide-gray-200 xl:dark:divide-gray-700">
          <header className="pt-6 xl:pb-6">
            <div className="space-y-1 text-center">
              <dl className="space-y-10">
                <div>

                </div>
              </dl>
              <div>
                <h1 className="text-3xl font-light leading-9 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10 md:text-5xl md:leading-14">Create your new WanderNote</h1>
              </div>
            </div>
          </header>
          <div
            className="divide-y divide-gray-200 pb-8 dark:divide-gray-700 xl:gap-x-6 xl:divide-y-0"
            style={{ gridTemplateRows: 'auto 1fr' }}
          >
            <div className="divide-y divide-gray-200 dark:divide-gray-700 xl:col-span-3 xl:row-span-2 xl:pb-0">
              <div className="prose max-w-none pt-10 pb-8 dark:prose-dark">
                <NoteForm />
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  )
}


export default NoteCreate