import { Link } from "react-router-dom"
import siteMetadata from "../../features/data/siteMetadata"


export default function Footer() {
  
  return (
    <footer>
      <div className="mt-14 flex flex-col items-center">
        <div className="mb-3 flex space-x-4"></div>

        <div className="mb-2 flex space-x-2 text-sm font-hairline text-gray-500 dark:text-gray-400">
          <div>{siteMetadata.author}</div>
          <div>{` • `}</div>
          <div>{`© ${new Date().getFullYear()}`}</div>
          <div>{` • `}</div>
          <Link to="/">{siteMetadata.title}</Link>
        </div>
      </div>
    </footer>
  )
}