// Service is for making http requests and sending the data back, and sending any data in localStorage
import axios from 'axios'
const API_USER_URL = "/api/users/"


// Get One Note @ /api/notes/:userId
const getUserById = async (_userId) => {
    const config = {
        method: "GET",
        withCredentials: true,
        // url: API_NOTE_URL + id,
    }
    const response = await axios.get(`${API_USER_URL}/${_userId}`, config)
    return response.data
}


///////

const userService = {
    getUserById,
}


export default userService