import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { getProfile, reset } from "../../features/reducers/auth/authSlice"
import { FaEdit } from "react-icons/fa"
import Spinner from "../../components/ui/Spinner"
import dateFormat from "dateformat"
import ProfileEdit from "../users/ProfileEdit"


function Profile() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user, notes, isLoading, isError, message } = useSelector((state) => state.auth)
  const [ editMode, setEditMode ] = useState(false)

///////

  const openEditMode = () => {
    setEditMode(true)
  }
  const closeEditMode = () => {
    setEditMode(false)
  }

///////

  useEffect(() => {
    if(isError) {
      console.log(message)
    }
    if(!user) {
      navigate("/login")
    }
    // console.log(user?.image)
    dispatch(getProfile())
    return () => { // To do smth when component unmounts, need to return from useEffects
      dispatch(reset())
    }
    
  }, [ user, navigate, isError, message, dispatch]) //dependency array


  if(isLoading) {
    return <Spinner />
  }

  function createNewSubmit() {
    navigate("/api/notes/new")
  }


///////

  return (
    <>
      <div className="divide-y divide-gray-200 dark:divide-gray-700 xs:justify-center">
        <div className="space-y-2 pt-6 pb-8 md:space-y-20 grid md:grid-cols-[30%_70%]">
          <h1 className="mb-7 text-4xl font-bold leading-9 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10 md:text-5xl md:leading-14">
            {notes?.user?.userName}
          </h1>
          {notes.notes?.length > 0 ? (
            <p className="pt-4 ml-auto text-end font-light leading-9 tracking-tight text-gray-900 text-3xl xs:text-xl sm:text-4xl xl:text-5xl dark:text-gray-100 sm:leading-10 md:leading-14">...all your notes in one place</p>
          ) : (
            null
          )}
        </div>

        <div className="items-start space-y-2 xl:grid xl:grid-cols-3 xl:gap-x-8 xl:space-y-0">
          <div className="flex flex-col items-center pt-4 2xs:pt-0">
            <div className="flex flex-col items-center pt-8">
              <div className="xl:col-span-3 xl:row-span-2 xl:py-3">
                <FaEdit onClick={openEditMode} className="fill-gray-300 hover:fill-gray-900" />
                {editMode ? (
                  <ProfileEdit closeEditMode={closeEditMode} />
                ) : (
                  <img 
                    src={notes?.user?.image} 
                    alt="avatar"
                    className="h-48 w-48 rounded-full"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="pt-4 pb-8 dark:prose-dark col-span-2 max-w-none">
            <section className='container'> 
              {notes.notes?.length > 0 ? (
                <div className="grid p-10 gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3">
                  {notes.notes?.map((note) => (
                    <div key={notes._id} note={note} className="border-darkerCrazyGreen bg-crazyGreen hover:bg-darkCrazyGreen rounded-lg cursor-pointer max-h-[10.3rem] min-h-[10.3rem] min-w-[11rem] max-w-[22rem] border-2 shadow m-auto w-full h-full ">
                      <Link to={`/api/notes/${note._id}`} note={note} className="no-underline ">
                        <div className="h-full w-full">
                            <span className="h-1/6 flex flex-row-reverse mt-1 pr-2 text-sm font-normal">{dateFormat(note.createdAt, " ddd d mmm")}</span>
                            <p className="border-y border-green-100 lg:text-sm font-medium h-4/6 flex items-center flex-grow justify-center sm:p-2 2xs:p-2 text-center overflow-hidden">{note.title}</p>
                            <span className="h-1/6 uppercase text-xs font-bold pl-2 align-center">{note.status}</span>
 								        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="mt-11">
                  <h3 className="text-center font-extralight">Add your first WanderNote</h3>
                  <div className="flex items-center justify-center mt-7">
                    <button onClick={createNewSubmit} className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-teal-300 to-lime-300 group-hover:from-teal-300 group-hover:to-lime-300 dark:text-white dark:hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800  sm:w-[200px]" type="submit">
                      <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0 sm:w-[200px]">
                        START CREATING
                      </span>
                    </button>
                  </div> 
                </div>
              )}
            </section>
          </div>

        </div>
      </div>
    </>
  )
  
}


export default Profile