import { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { login, reset } from "../../features/reducers/auth/authSlice"
import Spinner from "../../components/ui/Spinner"


function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  })

  const { email, password } = formData
  const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth)

  const navigate = useNavigate()
  const dispatch = useDispatch()


///////

  useEffect(() => {
    if (isError) {
      toast.error(message)
        // dispatch(reset())
    }
    if (isSuccess || user) {
      navigate('/profile')
    }
    dispatch(reset())

  }, [user, isError, isSuccess, message, navigate, dispatch])

///////

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const userData = {
      email,
      password,
    }
     dispatch(login(userData))
  }

  if(isLoading) {
    return <Spinner />
  }


///////

  return (
    <>
      <div className="bg-grey-200 max-h-screen flex flex-col">
            <div className="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2 mt-5 md:mt-10">
                <form onSubmit={onSubmit} className="bg-white px-6 py-8 rounded shadow-md text-black w-full">
                    <h1 className="mb-8 text-3xl text-center">Sign in to WanderNote</h1>
                    <input 
                        type="text"
                        className="block border border-grey-100 w-full p-3 rounded mb-4"
                        id="email" 
                        name="email" 
                        value={email} 
                        placeholder="Enter your email" 
                        onChange={onChange} 
                      />
                    <input 
                        type="password"
                        className="block border border-grey-100 w-full p-3 rounded mb-4"
                        id="password" 
                        name="password" 
                        value={password}
                        placeholder="Create a password" 
                        onChange={onChange}
                      />

                    <button
                      type="submit"
                      className="w-full text-center py-3 rounded text-white bg-gradient-to-r from-yellow200 via-yellow300 to-blue200 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-yellow100 font-bold text-sm px-5 mr-2 my-1"
                    >
                      SIGN IN
                    </button>
                </form>

                <div className="mt-6 text-gray-800">
                    New to WanderNote? 
                    <a className="no-underline text-mainBlue hover:text-blue400 pl-1" href="/signup">
                      Create an account.
                    </a>
                </div>
            </div>
        </div>
    </>
  )
}


export default Login