import { useState, useRef } from "react"
import { useParams, useNavigate, Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { updateNote } from "../../features/reducers/notes/noteSlice"
import { Editor } from "@tinymce/tinymce-react"


function NoteEditEditor() {
  const { id } = useParams()

  const currentNotes = useSelector(state => state.notes)
  const { currentNote, isLoading, isError, message } = currentNotes

  const [noteBody, ] = useState("")
  const [initialValue, ] = useState(currentNote?.noteBody)
  const editorRef = useRef(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()

///////

  const updateEditor = (e) => {
    const noteBody =  editorRef.current.getContent()

    let payload = {
      noteBody: noteBody,
      _id: id,
    }
    dispatch(updateNote( payload ))
    navigate(`/api/notes/${id}`)
  }


///////

  return (
    <div>
      <Editor
        className="no"
        apiKey="ttgzksvp8v1v124gm9xsdl2451hd4tb4lua6z908xog12v2r"
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={initialValue}
        value={noteBody}
        id="noteBody"
        type="noteBody"
        init={{
          height: 400,
          auto_focus: true,
          menubar: true,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'help',
            'wordcount',
            // 'autosave',
            'autoresize',
          ],
          toolbar:
            'undo redo | blocks ' +
            'bold italic forecolor backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
        }}
      />

      <div className="flex items-center justify-center mt-11">
        <button type="submit" onClick={updateEditor} className="text-gray-900 bg-gradient-to-r from-red-200 via-red-300 to-yellow-200 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 sm:w-[200px]">
          UPDATE
        </button>
        
        <Link to={`/api/notes/${id}`}>
          <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-red-200 via-red-300 to-yellow-200 group-hover:from-red-200 group-hover:via-red-300 group-hover:to-yellow-200 dark:text-white dark:hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400 sm:w-[200px]" type="submit">
            <span className="relative px-4 py-2 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0 sm:w-[200px]">
              CANCEL
            </span>
          </button>
        </Link>
      </div>     
    </div>
  )
}



export default NoteEditEditor