// Service is for making http requests and sending the data back, and sending any data in localStorage
import axios from 'axios'
const API_URL = '/'  // 'http://localhost:3000/' "https://wandernotes.onrender.com/" || 


// Register User (POST request)
const signup = async (userData) => {
  const response = await axios.post(`${API_URL}signup`, userData)

  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data))
  }
  return response.data
}


///////

// Login User (POST request)
const login = async (userData) => {
  const response = await axios.post(`${API_URL}login`, userData)


  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data))
  }
  return response.data
}


///////

// Logout User
const logout = async () => {
  localStorage.removeItem("user")
}


///////

// Get User Notes @ /profile (GET request)
const getProfile = async () => {
  const config = {
    method: "GET",
    withCredentials: true,
    url: `${API_URL}profile`,
  }
  const response = await axios.get(`${API_URL}profile`, config)

  return response.data
}


///////

// Edit Profile @ /profile (PUT request)
const editProfile = async ( userData, _id ) => {

  const config = {
      method: "PUT",
      data: userData, _id,
      withCredentials: true,
      // url: `${API_NOTE_URL}edit/${_id}`,
  }

  const response = await axios.put(`${API_URL}profile/edit`, userData, _id, config)
  return response.data
}


///////

const authService = {
  signup,
  logout,
  login,
  getProfile,
  editProfile,
  // getIndex,
}


export default authService