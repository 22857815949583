import { useState, useRef } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { createNote } from "../../features/reducers/notes/noteSlice"
import { Editor } from "@tinymce/tinymce-react"
import { toast } from "react-toastify"


function NoteForm() {

  const [title, setTitle] = useState("")
  const [noteBody, setNoteBody] = useState("")
  
  const editorRef = useRef(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()

///////

  const onSubmit = (e) => {
    e.preventDefault()

    const noteBody = editorRef.current.getContent() //{ format: "text" }

    if (title.length > 100) {
      toast.error("Input has to be less than 100 characters long.")
    } else {
      dispatch(createNote({ title, noteBody }))
      // setTitle("")
      navigate("/profile")
    }
  }


///////

  return (
    <div className="no" onSubmit={onSubmit}>
      <div className="form-group mb-7">
        <input 
          type="title" 
          name="title" 
          id="title" 
          value={title} 
          placeholder="Add a title"
          // maxLength="200" 
          onChange={(e) => setTitle(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <Editor
        className="no"
        apiKey="ttgzksvp8v1v124gm9xsdl2451hd4tb4lua6z908xog12v2r"
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={noteBody}
        value={noteBody}
        id="noteBody"
        type="noteBody"
        onChange={(e) => setNoteBody(e.target.value)}
        init={{
          height: 400,
          auto_focus: true,
          menubar: true,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'code',
            'help',
            'wordcount',
            // 'autosave',
          ],
          toolbar:
            'undo redo | blocks | formatselect ' +
            'bold italic forecolor backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          }}
        />
        <div className="flex items-center justify-center mt-11">
          <button onClick={onSubmit} type="submit" className="text-gray-600 bg-gradient-to-r from-teal-200 to-lime-200 hover:bg-gradient-to-l hover:from-teal-200 hover:to-lime-200 focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-teal-700 font-semibold rounded-lg text-sm px-5 py-2.5 text-center mr-2 sm:w-[200px]">
            ADD NOTE
          </button>
       </div>         
     </div>
  )
}


export default NoteForm