import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { signup, reset } from "../../features/reducers/auth/authSlice"
import Spinner from "../../components/ui/Spinner"


function Signup() {

  const [formData, setFormData] = useState({
    firstName: "",
    userName: "",
    email: "",
    password: "",
    password2: "",
  })

  const { firstName, userName, email, password, password2 } = formData

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth)

///////

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }
    if (isSuccess) {
      navigate("/profile")
    }
    dispatch(reset())

  }, [user, isError, isSuccess, message, navigate, dispatch])

///////

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    if (password !== password2) {
      toast.error("Passwords do not match")
    } else {
        const userData = {
          firstName,
          userName,
          email,
          password,
          password2,
        }
      dispatch(signup(userData))
    }
  }


///////

  if(isLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className="bg-grey-200 max-h-screen flex flex-col">
        <div className="container max-w-sm mx-auto flex flex-col items-center justify-center px-2 md:mt-2">
          <form onSubmit={onSubmit} className="bg-white px-6 py-8 rounded shadow-md text-black w-full">
            <h1 className="mb-8 text-3xl text-center">Sign up for WanderNote</h1>
            <input 
              type="text"
              className="block border border-grey-100 w-full p-3 rounded mb-4"
              id="email" 
              name="email" 
              value={email} 
              placeholder="Enter your email" 
              onChange={onChange} 
            />
            <input 
              type="text"
              className="block border border-grey-100 w-full p-3 rounded mb-4"
              id="userName" 
              name="userName" 
              value={userName} 
              placeholder="Enter a username" 
              onChange={onChange} 
            />
            <input 
              type="text"
              className="block border border-grey-100 w-full p-3 rounded mb-4"
              id="firstName" 
              name="firstName" 
              value={firstName} 
              placeholder="Your first name" 
              onChange={onChange} 
            />
            <input 
              type="password"
              className="block border border-grey-100 w-full p-3 rounded mb-4"
              id="password" 
              name="password" 
              value={password}
              placeholder="Create a password" 
              onChange={onChange}
            />
            <input 
              type="password"
              className="block border border-grey-light w-full p-3 rounded mb-4"
              id="password2" 
              name="password2" 
              value={password2}
              placeholder="Confirm Password"
              onChange={onChange}  
            />

            <button
              type="submit"
              className="w-full text-center py-3 rounded text-white bg-gradient-to-r from-blue200 via-blue300 to-yellow200 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue100 font-bold text-sm px-5 mr-2 my-1"
            >
              SIGN UP
            </button>
          </form>

          <div className="mt-6 text-gray-800">
            Already have an account? 
            <a className="no-underline text-mainBlue hover:text-blue400 pl-1" href="/login">
              Sign in.
            </a>
          </div>
        </div>
      </div>
    </>
  )
}


export default Signup