import { useEffect } from "react"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { getNotesAll, reset } from "../../features/reducers/notes/noteSlice"
import Spinner from "../../components/ui/Spinner"
import dateFormat from "dateformat"


function NoteAll() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.auth)
  const { notes, isLoading, isError, message } = useSelector((state) => state.notes)

///////

  useEffect(() => {
    if(isError) {
      console.log(message)
    }
    if(!user) {
      navigate("/login")
    }
    dispatch(getNotesAll())

    return () => {
      dispatch(reset())
    }
  }, [user, navigate, isError, message, dispatch])

  if(isLoading) {
    return <Spinner />
  }


///////

  return (
    <>
      <section className='heading'>
        {user ? <h1>Welcome Back {user.userName}</h1> : null}
        <p>GET ALL NOTES NOTEALL</p>
      </section>

      <div className="pt-8 pb-8 dark:prose-dark col-span-2 max-w-none">
        <section className='container'> 
          <div className="grid p-10 gap-5 xl:grid-cols-4 md:grid-cols-3 grid-cols-2 xs:grid-cols-1">
            {notes.map((note) => (
              <div key={notes._id} note={note} className="border-green-400 bg-green-300 hover:bg-green-400 rounded-lg cursor-pointer max-h-[10.3rem] min-h-[10.3rem] min-w-[8rem] xs:max-w-[22rem] border-2 shadow m-auto w-full h-full ">
                <Link to={`/api/notes/${note._id}`} note={note} className="no-underline ">
                  <div className=" h-full w-full">
                    <span className="h-1/6 pb-1 flex flex-row-reverse mt-1 pr-2">{dateFormat(note.createdAt, " ddd d mmm")}</span>
                    <p className="border-y border-green-100 text-md font-medium h-4/6 text-center flex items-center flex-grow justify-center">{note.title}</p>
                    <span className="h-1/6 uppercase text-xs font-bold pl-2 align-center">{note.status}</span>
 								  </div>
                </Link>
              </div>
             ))}
          </div>
        </section>
      </div>
    </>
  )
}

export default NoteAll