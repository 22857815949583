import { FaSignInAlt, FaSignOutAlt, FaUser, FaPlus, FaUserCircle } from "react-icons/fa"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { logout, reset } from "../../features/reducers/auth/authSlice"
import siteMetadata from "../../features/data/siteMetadata"
import MobileNav from "./MobileNav"


function Header() {

  const { user } = useSelector((state) => state.auth)

  const navigate = useNavigate()
  const dispatch = useDispatch()

///////

  function onLogout() {
    dispatch(logout())
    navigate("/")
    dispatch(reset())
    
    localStorage.clear()
    console.log("Logged out successfully")
  }


///////

  return (
    <header className="flex items-center justify-between py-10">
      <div>
        <Link to="/" aria-label={siteMetadata.headerTitle}>
          <div className="border-solid border-neutral-900 border-4 rounded-full  h-16 w-16 justify-evenly flex items-center xs:h-14 xs:w-14">
            {typeof siteMetadata.headerTitle === 'string' ? (
             <div className="text-2xl font-semibold sm:block xs:text-xl">
                {siteMetadata.headerTitle}
              </div>
            ) : (
              siteMetadata.headerTitle
            )}
          </div>
        </Link>
      </div>
      <div className="flex items-center text-base leading-5">
        <div className="hidden sm:block">
          <ul className="flex items-center text-base justify-between">
          {user ? (
            <>
              <li className="p-1 font-medium text-gray-900 hover:text-gray-500 dark:text-gray-100 sm:p-4">
                <Link to="/api/notes/new">
                  <FaPlus />
                </Link>
              </li>
              <li className="items-center text-gray-900 hover:text-gray-500 font-medium rounded-full pl-2 pr-1.25 py-1 text-center dark:border-gray-500 dark:text-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800 m-5">
                <Link className="flex items-center" to="/profile">
                  <FaUserCircle /><span className="px-2">{user ? <span>{user?.userName}</span> : null}</span>
                </Link>
              </li>
              <li>
                <button type="button" className="flex items-center text-gray-900 hover:text-white border border-gray-900 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full pl-4 pr-2.5 py-2 text-center dark:border-gray-500 dark:text-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800" onClick={onLogout}>
                  <FaSignOutAlt /> <span className="px-2">Sign out</span>
                </button>
              </li>
            </>
          ) : (
            <>
              <li className="mr-2">
                <Link to="/login" className="flex items-center font-medium text-gray-900 dark:text-gray-200 pl-4 pr-2.5 py-2 border border-transparent hover:bg-yellow000 focus:ring-2 focus:outline-none focus:ring-mainYellowBorder rounded-full text-center dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800">
                  <FaSignInAlt /><span className="px-2">Sign in</span>
                </Link>
              </li>
              <li>
                <Link to="/signup" className="flex items-center font-medium text-gray-900 dark:text-gray-100 pl-4 pr-2.5 py-2 hover:text-white border border-gray-900 hover:border-transparent hover:bg-blue000 focus:ring-2 focus:outline-none focus:ring-mainBlueBorder rounded-full text-center dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800">
                  <FaUser /> <span className="px-2">Sign up</span>
                </Link>
              </li>
            </>
          )}
          </ul>
        </div>
        <MobileNav />
      </div>
    </header>
  )
}


export default Header