import { useEffect } from "react"
import { Link } from "react-router-dom"
import { useParams, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { getUserById, reset } from "../../features/reducers/users/userSlice"
import Spinner from "../../components/ui/Spinner"
import dateFormat from "dateformat"


function ProfileById() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { userId } = useParams()
  const { user } = useSelector((state) => state.auth)

  const currentUsers = useSelector((state) => state.users)
  const { currentUser, isLoading, isError, message } = currentUsers

  let currentUserProfile = currentUser?.user

///////

  useEffect(() => {
    if(isError) {
      console.log(message)
    }
    if(user._id === userId) {
      navigate("/profile")
    }
    dispatch(getUserById(userId))

    return () => { // To do smth when component unmounts, need to return from useEffects
      dispatch(reset())
    }
  }, [user, navigate, isError, message, dispatch, userId, ]) //dependency array


  if(isLoading) {
    return <Spinner />
  }


///////

  return (
    <>
      <div className="divide-y divide-gray-200 dark:divide-gray-700">
        <div className="space-y-2 pt-6 pb-8 md:space-y-20 grid md:grid-cols-[30%_70%]">
          <h1 className="mb-14 text-4xl font-extrabold leading-9 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10 md:text-5xl md:leading-14">
            {currentUserProfile?.userName}
          </h1>
        </div>
        <div className="items-start space-y-2 xl:grid xl:grid-cols-3 xl:gap-x-8 xl:space-y-0">
          <div className="flex flex-col items-center pt-8">
            <div>
              {/* <img src={Image}
              alt="avatar"
              width="192px"
              height="192px"
              className="h-48 w-48 rounded-full"/> */}
              {/* <ImageCld /> */}
              {/* <img src={user.profilePhoto} alt="profile"/> */}
              {/* <AddImage /> */}
              {/* <img
                src={}
                alt="uploaded pics"
                style={{ width: "110px", borderRadius: "50%", height: "100px" }}
              /> */}
              {/* <AddImage src={newImage} /> */}

            </div>
          </div>
          <div className="prose max-w-none pt-8 pb-8 dark:prose-dark xl:col-span-2">
            <section className='container'> 
                <div className="container p-10 grid sm:grid-cols-2 md:grid-cols-3 gap-5">
                  {currentUser?.notes.map((note) => (
                    <div key={note._userId} note={note} className="bg-green-300 rounded-lg overflow-hidden shadow-lg display:block cursor: pointer ">
                      <Link to={`/api/notes/${note._id}`} note={note} className="no-underline ">
                      <div className="px-6 py-2">
                         <div className="border rounded-sm border-solid border-green-100 text-md my-2 word-break: break-all text-black">
                          <span className="border-b border-solid border-green-100 flex flex-row-reverse space-x-4 pr-2 py-0.5">{dateFormat(note.createdAt, " ddd d mmm")}</span>
                          <h2 className="truncate overflow-hidden ... mt-4 mb-8 pl-2">{note.title}</h2>
                          <span className="text-transform: uppercase text-xs font-bold flex flex-row-reverse space-x-4 pr-2 py-1">{note.status}</span>
                          </div>
 								      </div>
                       </Link>
                    </div>
                  ))}
                </div>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}


export default ProfileById