async function profilePhotoUpload(image) {

  const formData = new FormData()
    formData.append("file", image)
    formData.append("upload_preset", "cld-wn2-unsgnd")

    const res = await fetch(`https://api.cloudinary.com/v1_1/dxeyol9hp/image/upload`, {
      method: "POST",
      body: formData
  })
    const img = await res.json()
    // const imgUrl = img.secure_url //
    return img.secure_url
  }


export default profilePhotoUpload