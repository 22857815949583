import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import noteService from "./noteService"


const initialState = {
    notes: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    currentNote: null,
}


// Create New Note @ /api/notes/ (POST request)
export const createNote = createAsyncThunk("notes/create", 
    async (noteData, thunkAPI) => {
        try {
            const { user } = thunkAPI.getState().auth.user
            return await noteService.createNote(noteData, user)
        } catch (error) {
            const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)  
        }
    }
)



// Update a Note @ /api/notes/:id (PUT request)
export const updateNote = createAsyncThunk("notes/edit",
    async (noteData, thunkAPI) => {
      try {
        const { user } = thunkAPI.getState().auth
        return await noteService.updateNote( noteData, noteData._id, user)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
)



// Get One Note @ /api/notes/:id (GET request)
export const getNoteByID = createAsyncThunk("notes/getNoteById", 
    async (_id, thunkAPI) => {
        try {
            return await noteService.getNoteByID(_id)
        } catch (error) {
            const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
        }
    }
)



// Get All Notes @ /api/notes/ (GET request)
export const getNotesAll = createAsyncThunk("notes/getNotesAll", 
    async (_, thunkAPI) => {
        try {
            const { user } = thunkAPI.getState().auth.user
            return await noteService.getNotesAll(user)
        } catch (error) {
            const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
        }
    }
)


// Delete a Note @ /api/notes/:id (DELETE request)
export const deleteNote = createAsyncThunk("notes/delete",
    async (_id, thunkAPI) => {
      try {
        const user = thunkAPI.getState().auth.user
        return await noteService.deleteNote(_id, user)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
)


///////

export const noteSlice = createSlice({
    name: "note",
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(createNote.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createNote.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.notes.push(action.payload)
            })
            .addCase(createNote.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getNotesAll.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getNotesAll.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.notes = action.payload
            })
            .addCase(getNotesAll.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updateNote.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateNote.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                const updatedNote = {...action.payload.note}
                state.notes = state.notes.map(note => {
                    if (note._id === updatedNote._id) {
                        return updatedNote
                    }
                    else return note
                })
                state.currentNote = action.payload
            })
            .addCase(updateNote.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getNoteByID.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getNoteByID.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.currentNote = action.payload
            })
            .addCase(getNoteByID.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteNote.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteNote.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.notes = state.notes.filter(
                    (note) => note._id !== action.payload
                )
                //state.currentNote = null
            })
            .addCase(deleteNote.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }
}) 



export const { reset } = noteSlice.actions
export default noteSlice.reducer