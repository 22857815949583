import SectionContainer from "./SectionContainer"
import Header from "../ui/Header"
import Footer from "../ui/Footer"


function LayoutWrapper  ({ children }) {

  return (
    <SectionContainer>
      <div className="flex h-screen flex-col justify-between">
        <Header />
        <main className="mb-auto">{children}</main>
        <Footer />
      </div>
    </SectionContainer>
  )
}


export default LayoutWrapper