import { useEffect, useState } from "react"
import { useParams, useNavigate, Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { getNoteByID, deleteNote, reset } from "../../features/reducers/notes/noteSlice"
import { FaTrash, FaEdit } from "react-icons/fa"
import Spinner from "../../components/ui/Spinner"
import dateFormat from "dateformat"
import parse from "html-react-parser"
import Image from "../../components/social-icons/avatar.png"


function Note() { // note
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { id } = useParams();
  const { user } = useSelector((state) => state.auth)

  const currentNotes = useSelector(state => state.notes)
  const { currentNote, isLoading, isError, message } = currentNotes

  let noteAuthor = currentNotes?.currentNote?.user
  
  const [ editMode, setEditMode ] = useState(false)

      const openEditMode = () => {
      setEditMode(true)
    }
    const closeEditMode = () => {
      setEditMode(false)
    }

///////

  useEffect(() => {
    if(isError) {
      console.log(message)
    }
    if(!user) {
      navigate("/login")
    }
    dispatch(getNoteByID(id))

    return () => {
     dispatch(reset())
    }

}, [user, navigate, isError, message, dispatch, id])

///////

  const onDeleteNote = () =>  {
    dispatch(deleteNote(currentNote._id))
    navigate("/profile")
  }

  if(isLoading) {
    return <Spinner />
  }


///////

  return (
    <>
      <article>
        <div className="xl:divide-y xl:divide-white xl:dark:divide-gray-700">
          <header className="pt-6 xl:pb-6">
            <div className="space-y-1 text-center">
              <dl className="space-y-10">
                <div>
                  <dt className="sr-only">Published on</dt>
                  <dd className="text-base font-medium leading-6 text-gray-500 dark:text-gray-400 xs:font-normal">
                    <time className="">
                    {dateFormat(currentNote?.createdAt, "dddd, dd mmmm, yyyy")}
                    </time>
                  </dd>
                </div>
              </dl>
              </div>
          </header>

          <div className="pt-2 pb-8">
            <h1 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10 md:text-5xl md:leading-14 text-center">
              {currentNote?.title}
            </h1>
          </div>

          <div className="brdr"></div>
          
          <div
            className="divide-y divide-gray-200 pb-8 dark:divide-gray-700 xl:flex xl:divide-y-0"
            style={{ gridTemplateRows: 'auto 1fr' }}
          >
            <dl className="pt-1.5 pb-3 xl:border-b xl:border-gray-200 xl:pt-2 xl:dark:border-gray-700">
              <dt className="sr-only">Author</dt>
              <dd className="flex xl:grid">
                <div className="xl:border-b xl:border-gray-200 xl:dark:border-gray-700">
                  <span className="p-1"><Link to={`/api/notes/edit/${currentNote?._id}`} note={currentNote?._id} ><FaEdit /></Link></span>
                  <span className="p-1" onClick={onDeleteNote}><FaTrash /></span>
                </div>

                <ul className="flex justify-center space-x-8 sm:space-x-12 xl:space-x-0 xl:space-y-8 m-auto xl:m-0 xl:pt-8 xl:w-[80px] pr-4 md:pr-0 w-full">
                  {/* {noteAuthor.map((user) => ( */}
                    <li className="justify-center items-center text-center xl:text-start w-full" key={noteAuthor?.firstName}>
                      {Image && (
                        <img
                          src={noteAuthor?.image}
                          width="38px"
                          height="38px"
                          alt="avatar"
                          className="h-10 w-10 rounded-full m-auto xl:m-0"
                        />
                      )}
                      <dl className="text-sm font-medium leading-5 mt-2 xl:mt-1">
                        <dt className="sr-only">Name</dt>
                        <dd className="text-gray-900 dark:text-gray-100">{noteAuthor?.firstName}</dd>
                        <dd key={currentNote?._id} currentnote={currentNote} >
                          <Link to={`/api/users/${noteAuthor?._id}`} className="no-underline text-primary-500 hover:text-primary-600 dark:hover:text-primary-400">
                            {noteAuthor?.userName}
                          </Link>
                        </dd>
                      </dl>
                    </li> 
                </ul>
              </dd>
            </dl>
            <div className="divide-y divide-gray-200 dark:divide-gray-700 xl:w-[850px] xl:px-16 pt-4">
              <div className="no pb-20">
                {currentNote?.noteBody && parse(currentNote?.noteBody)}
              </div>
              <div className="pt-12 pb-6 text-sm text-gray-700 dark:text-gray-300">
                {/* links */}
              </div>
              {/* <Comments frontMatter={frontMatter} /> */}
            </div>
          </div>
          <footer className="">
            <div className="divide-gray-200 text-sm font-medium leading-5 dark:divide-gray-700 xl:col-start-1 xl:row-start-2 xl:divide-y"></div>
            <div className="pt-4 xl:pt-8">
              <Link
                to={`/api/users/${noteAuthor?._id}`}
                className="text-primary-500 hover:text-primary-600 dark:hover:text-primary-400"
              >
                &larr; Back to profile
              </Link>
            </div>
          </footer>
        </div>
      </article>
    </>
  )
}


export default Note