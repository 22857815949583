import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { ToastContainer } from "react-toastify"
import LayoutWrapper from "./components/other/LayoutWrapper"
import Index from "./pages/auth/Index.jsx"
import Login from "./pages/auth/Login"
import Signup from "./pages/auth/Signup"
import Profile from "./pages/users/Profile"
import ProfileEdit from "./pages/users/ProfileEdit"
import Note from "./pages/notes/Note"
import NoteEditEditor from "./pages/notes/NoteEditEditor"
import NoteEdit from "./pages/notes/NoteEdit"
import NoteAll from "./pages/notes/NoteAll"
import NoteCreate from "./pages/notes/NoteCreate"
import ProfileById from "./pages/users/ProfileById"
import "react-toastify/dist/ReactToastify.css"


function App() {
  const { user } = useSelector((state) => state.auth)

  return (
    <>
      <Router>
        <LayoutWrapper>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/api/notes" element={<NoteAll />} />
          <Route path="/api/notes/new" element={<NoteCreate />} />
          <Route path="/api/notes/:id" element={<Note />} />
          <Route path="/api/notes/edit/:id" element={<NoteEdit />} />
          <Route path="/api/notes/edit/:id/editor" element={<NoteEditEditor />} />
          <Route path="/api/users/:userId" element={<ProfileById />} />
          <Route path="/profile" element={!user ? <Navigate replace to="/" /> : <Profile />} />
          <Route path="/profile" element={<ProfileEdit />} />
        </Routes>
        </LayoutWrapper> 
      </Router>
      <ToastContainer />
    </>
  )
}


export default App