import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from './authService'


// Get user from localStorage
const user = JSON.parse(localStorage.getItem('user'))


// An Object, pertains just to the user part of our state/the authentication
const initialState = {
  user: user ? user : null,
  isError: false, // if we get an error back from the server, we can make that true
  isSuccess: false,
  isLoading: false,
  message: "",
  notes: [],
  users: [],
  currentUser: null,
}


// Register User
export const signup = createAsyncThunk(
  'auth/signup',
  async (user, thunkAPI) => {
    try {
      return await authService.signup(user)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


///////

// Login User
export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
  try {
    return await authService.login(user)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const logout = createAsyncThunk('auth/logout', async () => {
  await authService.logout()
})


///////

// Get Logged-in User Profile @ /profile
export const getProfile = createAsyncThunk("auth/getProfile", 
    async (_, thunkAPI) => {
        try {
            const { user } = thunkAPI.getState().auth.user
            // const { notes } = thunkAPI.getState().auth.user.notes

            return await authService.getProfile(user)
        } catch (error) {
            const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
        }
    }
)


///////

// Edit a Note @ /api/notes/:id
export const editProfile = createAsyncThunk("auth/editProfile",
    async (userData, thunkAPI) => { // _id
      try {
        const { user } = thunkAPI.getState().auth
        console.log("authSlice:", user._id)
        return await authService.editProfile( userData, userData._id, user, user._id) // _id, user, 
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
)


export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
      state.notes = []
      // state.currentNote = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signup.pending, (state) => {
        state.isLoading = true
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
      })
      .addCase(signup.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null
      })
      .addCase(getProfile.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getProfile.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.notes = action.payload
      })
      .addCase(getProfile.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
      })
      .addCase(editProfile.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editProfile.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        const updatedProfile = {...action.payload.user}
        state.user = state.users.map(user => {
            if (user._id === updatedProfile._id) {
                return updatedProfile
            }
            else return user
        })
        state.currentUser = action.payload
      })
      .addCase(editProfile.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

export const { reset } = authSlice.actions
export default authSlice.reducer