import { useEffect, useState, useRef } from "react"
import { useParams, useNavigate, Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { updateNote, getNoteByID, reset } from "../../features/reducers/notes/noteSlice"
import { toast } from "react-toastify"


function NoteEdit() {
  const { id } = useParams()
  const { user } = useSelector((state) => state.auth)

  const currentNotes = useSelector(state => state.notes)
  const { currentNote, isLoading, isError, message } = currentNotes

  const [title, setTitle] = useState(currentNote?.title)

  const dispatch = useDispatch()
  const navigate = useNavigate()

///////

  useEffect(() => {
    if(isError) {
      console.log(message)
    }
    if(!user) {
      navigate("/login")
    }

    dispatch(getNoteByID(id))

    return () => {
     dispatch(reset())
    }
}, [user, navigate, isError, message, dispatch, id])


///////

  const updateTitle = (e) => {
    e.preventDefault()

    let payload = {
      title: title,
      _id: id,
    }
    if (title.length > 100) {
      toast.error("Input has to be less than 100 characters long.")
    } else {
      dispatch(updateNote(payload))
      navigate(`/api/notes/${id}`)
    }
  }


///////

  return (
    <>
      <div className="mt-7">
        <label htmlFor="title" className="text-xs font-medium">Title</label>
        <input 
          type="title" 
          name="title" 
          id="title"
          defaultValue={title}
          onChange={(e) => setTitle(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="flex items-center justify-center mt-11">
        <button type="submit" onClick={updateTitle}  className="text-gray-900 bg-gradient-to-r from-red-200 via-red-300 to-yellow-200 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 sm:w-[200px]">
          UPDATE
        </button>

        <Link to={`/api/notes/${id}`}>
          <button type="submit" className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-red-200 via-red-300 to-yellow-200 group-hover:from-red-200 group-hover:via-red-300 group-hover:to-yellow-200 dark:text-white dark:hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400 sm:w-[200px]">
            <span className="relative px-4 py-2 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0 sm:w-[200px]">
              CANCEL
            </span>
          </button>
        </Link>
      </div>    

      <div className="flex items-center justify-center mt-11">
        <Link to={`/api/notes/edit/${currentNote?._id}/editor`} note={currentNote?._id} className="relative inline-block px-8 py-3 font-medium group xs:p-4 xs:py-2" >
          <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-black group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
          <span className="absolute inset-0 w-full h-full bg-white border-2 border-black group-hover:bg-black"></span>
          <span className="relative text-black group-hover:text-white">EDIT NOTE</span>
        </Link>
      </div>
    </>
  )
}




export default NoteEdit