import { FaSignInAlt, FaSignOutAlt, FaUser, FaPlus, FaUserCircle } from "react-icons/fa"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { logout, reset } from "../../features/reducers/auth/authSlice"


const MobileNav = () => {

  const { user } = useSelector((state) => state.auth)

  const navigate = useNavigate()
  const dispatch = useDispatch()

///////

  function onLogout() {
    dispatch(logout())
    navigate("/")
    dispatch(reset())
      
    localStorage.clear()
  }


///////

  return (
    <div className="sm:hidden">
      <nav>
        <div className="flex items-center text-base leading-5">
          <ul className="flex items-center text-base justify-between">
            {user ? (
            <>
              <li className="p-1 font-medium text-gray-900 hover:text-gray-500 dark:text-gray-100 sm:p-4 px-3">
                <Link to="/api/notes/new">
                  <FaPlus />
                </Link>
              </li>
              <li className="items-center text-gray-900 hover:text-gray-500 font-medium rounded-full pl-2 pr-1.25 py-1 text-center dark:border-gray-500 dark:text-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800 m-2 px-3">
                <Link className="flex items-center" to="/profile">
                  <FaUserCircle />
                </Link>
              </li>
              <li className="px-2">
                <button type="button" className="flex items-center text-gray-900 hover:text-white border-2 border-gray-900 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full pl-2.5 pr-2 py-2 text-center dark:border-gray-500 dark:text-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800" onClick={onLogout}>
                  <FaSignOutAlt />
                </button>
              </li>
            </>
            ) : (
            <>
              <li className="mr-2 xs:mr-1">
                <Link to="/login" className="flex items-center font-medium text-gray-900 dark:text-gray-200 pl-4 pr-2.5 py-2 border border-transparent hover:bg-yellow000 focus:ring-2 focus:outline-none focus:ring-mainYellowBorder rounded-full text-center dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800 xs:pl-3 xs:pr-2">
                  <FaSignInAlt /><span className="px-2 xs:px-1">Sign in</span>
                </Link>
              </li>
              <li>
                <Link to="/signup"
                className="flex items-center font-medium text-gray-900 dark:text-gray-100 pl-4 pr-2.5 py-2 hover:text-white border border-gray-900 hover:border-transparent hover:bg-blue000 focus:ring-2 focus:outline-none focus:ring-mainBlueBorder rounded-full text-center dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800 xs:pl-2 xs:pr-1.5 xs:py-1">
                  <FaUser /> <span className="px-2 xs:px-1">Sign up</span>
                </Link>
              </li>
            </>
            )}
          </ul>
        </div>
      </nav>
    </div>
  )
}


export default MobileNav