import { configureStore } from "@reduxjs/toolkit"
import authReducer from "../reducers/auth/authSlice"
import noteReducer from "../reducers/notes/noteSlice"
import userReducer from "../reducers/users/userSlice"


export const store = configureStore({
    reducer: {
        auth: authReducer,
        notes: noteReducer,
        users: userReducer,
    },
})


export default store